// export const baseurl = process.env.REACT_APP_BASE_URL;
export const baseurl = "https://node-royaltysdk.mobiloitte.io";
// export const baseurl = "http://172.16.1.53:2044";

// export const baseurl = "https://nodesdk.hovr.site";

let base = `${baseurl}/api/v1`;
let url = `${baseurl}/api/v1/admin`;

const ApiConfig = {
  login: `${url}/login`,
  forgotPassword: `${url}/password/forgot`,
  verifyOTP: `${url}/password/verify-otp`,
  resendOTP: `${url}/password/resend-otp`,
  resetPassword: `${url}/password/reset`,
  profile: `${url}/profile`,
  changePassword: `${url}/profile/password`,
  users: `${url}/users`,
  whitelists: `${url}/whitelists`,
  deleteFAQ: `${url}/faqs`,
  FAQs: `${url}/faqs`,
  addFAQ: `${url}/faqs`,
  updateFaq: `${url}/faqs`,
  dashboard: `${url}/dashboard`,
  getstaticContent: `${url}/static-content`,
  addUpdateStaticContent: `${url}/static-content`,
  getInfluencerRequestList: `${url}/influencers/requests`,
  approveOrRejectInfluencer: `${url}/influencers`,
  brandlists: `${url}/brands`,
  approveRejectBrand: `${url}/brands`,
  getInfluencerCategory: `${url}/influencer-categories`,
  deleteInfluencerCategory: `${url}/influencer-categories`,
  addInfluencerCategory: `${url}//influencer-categories`,
  editInfluencerCategory: `${url}/influencer-categories`,
  addCategory: `${url}/categories`,
  getCategory: `${url}/categories`,
  editCategory: `${url}/categories`,
  deleteNftCategory: `${url}/nft-categories`,

  viewBrand: `${base}/brand/brands`,
  getcategoryforEndpoint: `${url}/getcategoryforEndpoint`,
  categories: `${url}/endPoints/categories`,
  subcategories: `${url}/api-reference/subcategories`,
  endpointDescription: `${url}/endpoints/description`,
  deleteEndpoint: `${url}/endpoints`,
  blockUnblockEndpoint: `${url}/endpoints`,

  viewEndPointDetails: `${url}/endpoints/details`,
  endpointList: `${url}/endpoints`,

  getHitrates: `${url}/hitrates`,
  editHitrates: `${url}/hitrates`,

  addAnnouncement: `${url}/announcements`,
  editAnnouncement: `${url}/announcements`,
  getAllAnnouncement: `${url}/announcements`,
  deleteAnnouncement: `${url}/announcements`,
  blockUnblockAnnouncement: `${url}/announcements`,

  addPartner: `${url}/partners`,
  editPartner: `${url}/partners`,
  partnerList: `${url}/partners`,
  deletePartner: `${url}/partners`,
  blockUnblockPartner: `${url}/partners`,
};
export default ApiConfig;
